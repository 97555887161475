@import "../../utils/css/_variables";

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: $modal;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 280px;
  background: $modal-bg;
  color: $modal-color;
  border-radius: $border-radius;
}

.modal__text {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
}

.modal__btn {
  border: 0;
  outline: 0;
  background: $modal-color;
  color: $modal-bg;
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
  padding: 1px 5px 3px 5px;
  cursor: pointer;
  border-radius: $border-radius;

  @mixin hover {
    background: lighten($modal-color, 10);
  }
  &:active {
    @include hover;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }
}
