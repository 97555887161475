@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=cyrillic,cyrillic-ext);
.header{position:fixed;top:0;width:100%;height:60px;z-index:10;background:#222b33;border-bottom:1px solid #ddd}.header__inner{width:90%;max-width:1200px;height:100%;margin:0 auto;display:flex;align-items:center}.header__logo{height:50%;margin-right:5rem;cursor:pointer}.header__logo-img{height:100%}.header__logo-img-mark{margin-right:8px}.header__logo-word-mark{margin-right:50px}.header__menu{display:flex;color:#fff}@media (max-width: 767px){.header__menu{display:none}}.header__menu-item{text-transform:uppercase;margin-right:15px;cursor:pointer;transition:all 0.15s ease-out}.header__menu-item:active{color:#009af8}@media (hover: hover) and (pointer: fine){.header__menu-item:hover{color:#009af8}}.menu__btn{display:none;margin-left:auto;font-size:2rem;color:#fff;cursor:pointer;transition:all 0.3s ease-out}@media (max-width: 767px){.menu__btn{display:block}}.menu__btn.menu__btn_close{transform:rotate(180deg)}.menu__btn.menu__btn_close .menu__btn-line:nth-child(1){transform:rotate(45deg) translate(6px, 6px)}.menu__btn.menu__btn_close .menu__btn-line:nth-child(2){opacity:0}.menu__btn.menu__btn_close .menu__btn-line:nth-child(3){transform:rotate(-45deg) translate(5px, -5px)}.menu__btn:active .menu__btn-line{background:#009af8;transition:all 0.3s ease-out,background-color 0.2s ease-out}@media (hover: hover) and (pointer: fine){.menu__btn:hover .menu__btn-line{background:#009af8;transition:all 0.3s ease-out,background-color 0.2s ease-out}}.menu__btn-line{width:28px;height:3px;margin:5px 0;background:#fff;transition:all 0.3s ease-out}

.menu-mobile{position:fixed;top:60px;left:-100%;width:100%;background:#222b33;transition:all 0.3s ease-out;z-index:10}.menu-mobile_open{left:0}.menu-mobile__item{display:flex;justify-content:center;align-items:center;height:100px;max-height:calc((100vh - 60px) / 4);color:#fff;text-transform:uppercase;border-bottom:1px solid #ddd;cursor:pointer;transition:all 0.15s ease-out}.menu-mobile__item:active{color:#009af8}@media (hover: hover) and (pointer: fine){.menu-mobile__item:hover{color:#009af8}}

.attention{position:fixed;top:60px;width:100%;background:red;color:#fff;border-bottom:1px solid #fff;z-index:3}.attention__inner{width:90%;max-width:1200px;display:flex;align-items:center;justify-content:center;text-transform:uppercase;text-align:center;padding:0.25rem 0;margin:0 auto;font-size:0.8rem}.attention__inner .attention__main{margin-right:1rem}.attention__inner-contacts-link{cursor:pointer;color:chartreuse}.attention__inner-contacts-link:active{color:#6c0}@media (hover: hover) and (pointer: fine){.attention__inner-contacts-link:hover{color:#6c0}}.attention__close{cursor:pointer;color:#fff;font-size:1.5rem}.attention__close:active{color:#e6e6e6}@media (hover: hover) and (pointer: fine){.attention__close:hover{color:#e6e6e6}}.attention__close svg{display:flex}

.hero{margin-top:60px;display:flex;justify-content:center;padding-top:80px;height:595px;max-height:calc(100vh - 60px);background:url(/static/media/hero-bg.25bb75c9.jpg) center no-repeat;object-fit:contain}.hero__inner{text-align:center;width:450px;max-width:80vw}.hero__text{margin:1.5rem 0;font-size:1.5rem;font-weight:300;color:#fff;text-transform:uppercase}@media (max-width: 767px){.hero__text{font-size:1.1rem}}.hero-logo{display:flex;justify-content:center;height:100px}@media (max-width: 767px){.hero-logo{height:60px}}.hero__logo-img,.hero__logo-words{height:100%}.hero__logo-img{margin-right:20px;-webkit-animation:logo-spin infinite 20s linear;animation:logo-spin infinite 20s linear}@media (max-width: 767px){.hero__logo-img{margin-right:13px}}@-webkit-keyframes logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@keyframes logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}

.modal-bg{position:fixed;top:0;left:0;height:100vh;width:100vw;background:rgba(0,0,0,0.9);z-index:15;display:flex;justify-content:center;align-items:center}.modal{display:flex;flex-direction:column;justify-content:center;align-items:center;height:120px;width:280px;background:#f2f3f8;color:#003662;border-radius:5px}.modal__text{margin-bottom:1rem;font-size:1.2rem;text-transform:uppercase;text-align:center}.modal__btn{border:0;outline:0;background:#003662;color:#f2f3f8;font-family:"Open Sans", sans-serif;font-size:1.3rem;padding:1px 5px 3px 5px;cursor:pointer;border-radius:5px}.modal__btn:active{background:#005295}@media (hover: hover) and (pointer: fine){.modal__btn:hover{background:#005295}}

.spinner{display:flex;justify-content:center;height:100px}

.exchange-data__title{font-size:2rem;text-transform:uppercase;margin-bottom:0.5rem}.exchange-data__selected{width:250px;height:50px;background:#fff;color:#0084d4;border-radius:5px;transition:all 0.15s ease-out;position:relative;z-index:2;display:flex;align-items:center;cursor:pointer}.exchange-data__selected:active .exchange-data__select-arrow{color:#3bb5ff}@media (hover: hover) and (pointer: fine){.exchange-data__selected:hover .exchange-data__select-arrow{color:#3bb5ff}}.exchange-data__currency-img{height:80%;margin:0 0.5rem}.exchange-data__currency-name{flex:1 1;margin-right:0.5rem;font-size:2rem}.exchange-data__select-arrow{display:flex;align-items:center;height:100%;padding:0.5rem;font-size:2rem;transition:all 0.15s ease-out}.exchange-data__select-arrow_is-opened{transform:rotate(-180deg)}.exchange-data__select-options{width:250px;height:50px;background:#fff;color:#0084d4;border-radius:5px;transition:all 0.15s ease-out;position:absolute;height:0;padding:0;margin-top:-5px;border:1px solid #0084d4;overflow:hidden;z-index:1}.exchange-data__select-options-item{display:flex;align-items:center;height:50px;border-bottom:1px solid #0084d4;cursor:pointer}.exchange-data__select-options-item:last-child{border-bottom:0}.exchange-data__select-options-item:active{background:#e6e6e6}@media (hover: hover) and (pointer: fine){.exchange-data__select-options-item:hover{background:#e6e6e6}}.exchange-data__input{width:250px;height:50px;background:#fff;color:#0084d4;border-radius:5px;transition:all 0.15s ease-out;margin-top:1rem;padding:10px 15px;font-size:2rem;line-height:normal}

.table{margin-top:1rem;width:100%;text-transform:uppercase;font-size:1.3rem;font-weight:300;color:#fff;border-radius:5px;overflow:hidden}@media (max-width: 767px){.table{font-size:0.8rem}}@media (max-width: 550px){.table{font-size:0.65rem}}.table th{background:#1a1a1a;width:calc(100% / 3)}.table td,.table th{vertical-align:middle;padding:0.5rem;border-right:1px solid #fff}.table td:last-child,.table th:last-child{border-right:0}@media (max-width: 550px){.table td,.table th{padding:0.5rem 0.25rem}}.table tbody tr:nth-child(even){background:#444}.table tbody tr:nth-child(odd){background:#5e5e5e}.table tbody tr:active{background:green}@media (hover: hover) and (pointer: fine){.table tbody tr:hover{background:green}}.table tbody td{text-align:center}



.calculator-section{display:flex;justify-content:center;padding:2rem 0;background:#0084d4;color:#fff}.calculator-section__inner{width:90%;max-width:1200px}.calculator{display:flex;align-items:center;justify-content:center}@media (max-width: 767px){.calculator{flex-direction:column}}.calculator__swaper{margin:1rem 2rem;font-size:3rem;cursor:pointer;transition:all 0.15s ease-out}.calculator__swaper:active{color:#ccc}@media (hover: hover) and (pointer: fine){.calculator__swaper:hover{color:#ccc}}@media (max-width: 767px){.calculator__swaper{transform:rotate(90deg)}}

.exchange{display:flex;justify-content:center;padding:2rem 0;background:#134069;color:#fff;text-transform:uppercase}.exchange__inner{width:90%;max-width:1200px}.exchange__desc{text-align:center;font-size:1.3rem;margin:1rem;line-height:1.3}@media (max-width: 767px){.exchange__desc{font-size:1rem}}.exchange__contact-link{cursor:pointer;color:chartreuse}.exchange__contact-link:active{color:#6c0}@media (hover: hover) and (pointer: fine){.exchange__contact-link:hover{color:#6c0}}.exchange__form{text-align:center}.exchange__inputs{display:flex;justify-content:center}@media (max-width: 767px){.exchange__inputs{flex-direction:column;align-items:center}}.exchange__form-group{position:relative;display:flex;flex-direction:column;align-items:flex-start;margin:1rem}.exchange__form-group small{position:absolute;bottom:-16px;font-size:0.8rem;color:#f58}.exchange__input{width:250px;height:50px;padding:10px 15px;outline:0;border:0;border-radius:5px;font-family:"Open Sans", sans-serif;font-size:2rem;text-transform:uppercase;color:#134069;line-height:normal}.exchange__input_invalid{border:2px solid #f58}.exchange__btn{margin:1rem;padding:5px 10px;background:#0084d4;color:#fff;border:0;outline:0;border-radius:5px;font-family:"Open Sans", sans-serif;font-size:2rem;text-transform:uppercase;cursor:pointer}.exchange__btn:active{background:#0064a1}@media (hover: hover) and (pointer: fine){.exchange__btn:hover{background:#0064a1}}@media (max-width: 767px){.exchange__btn{font-size:1.5rem}}.exchange__error{font-size:0.8rem;text-align:center}

.about-us{padding:1rem 0;background:#f2f3f8;color:#003662;text-transform:uppercase}.about-us__inner{width:90%;max-width:1200px;margin:0 auto;display:flex;flex-wrap:wrap;justify-content:space-around}.about-us__unit{max-width:300px;text-align:center;margin:1rem 2rem 2rem 2rem}.about-us__unit-img{max-width:90vw;margin:1rem 0}.about-us__unit-title{font-size:36px}@media (max-width: 767px){.about-us__unit-title{font-size:1.8rem}}.about-us__unit-desc{line-height:1.3}

.contacts{display:flex;justify-content:center;padding:2rem 0;background:#1f252e;color:#8f9297;text-transform:uppercase;font-weight:300}.contacts__inner{width:90%;max-width:1200px;margin:0 auto;display:flex;flex-wrap:wrap;justify-content:space-around}.contacts__info{display:flex;flex-direction:column;justify-content:space-around;margin:0 2rem 2rem 2rem}@media (max-width: 767px){.contacts__info{margin:0 0.5rem 2rem 0.5rem}}.contacts__info-unit{display:flex;align-items:center;height:3rem;font-size:1.5rem}@media (max-width: 767px){.contacts__info-unit{font-size:1rem}}.contacts__info-unit_clickable{color:#8f9297;text-decoration:none;cursor:pointer}.contacts__info-unit_clickable:hover{color:#c4c5c8}.contacts__info-unit_clickable svg{display:flex}.contacts__info-unit-icon{margin:3px 20px 0 0}@media (max-width: 767px){.contacts__info-unit-icon{margin:2px 10px 0 0}}.contacts__logo{margin-bottom:30px}@media (max-width: 767px){.contacts__logo{margin-bottom:20px}}.contacts__map{border-radius:5px;overflow:hidden}.contacts__map-iframe{height:400px;width:600px;max-width:600px;max-height:80vh}@media (max-width: 767px){.contacts__map-iframe{width:90vw}}

*{margin:0;padding:0;outline:0;border:0;box-sizing:border-box;font:inherit;font-size:100%;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:"";content:none}table{border-collapse:collapse;border-spacing:0}

*{-webkit-user-select:none;-ms-user-select:none;user-select:none;-webkit-tap-highlight-color:transparent}input{-webkit-user-select:auto;-ms-user-select:auto;user-select:auto}body{font-family:"Open Sans", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}

