@import "../../utils/css/_variables";

.table {
  margin-top: 1rem;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 300;
  color: $calculator-color;
  border-radius: $border-radius;
  overflow: hidden;

  @media (max-width: 767px) {
    font-size: 0.8rem;
  }
  @media (max-width: 550px) {
    font-size: 0.65rem;
  }

  th {
    background: #1a1a1a;
    width: calc(100% / 3);
  }

  td,
  th {
    vertical-align: middle;
    padding: 0.5rem;
    border-right: 1px solid $calculator-color;

    &:last-child {
      border-right: 0;
    }

    @media (max-width: 550px) {
      padding: 0.5rem 0.25rem;
    }
  }

  tbody {
    // color:crimson
    tr {
      &:nth-child(even) {
        background: #444;
      }
      &:nth-child(odd) {
        background: lighten(#444, 10);
      }

      @mixin hover {
        background: (green);
      }
      &:active {
        @include hover;
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          @include hover;
        }
      }
    }

    td {
      text-align: center;
    }
  }
}
