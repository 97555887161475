@import "../../utils/css/_variables";
@import "../../utils/css/_mixins";

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: $header-height;
  z-index: $header-z-i;
  background: $menu-bg;
  border-bottom: 1px solid $border;
}

.header__inner {
  @include app-inner-width;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.header__logo {
  height: 50%;
  margin-right: 5rem;
  cursor: pointer;
}

.header__logo-img {
  height: 100%;
}

.header__logo-img-mark {
  margin-right: 8px;
}
.header__logo-word-mark {
  margin-right: 50px;
}

.header__menu {
  display: flex;
  color: $menu-color;

  @media (max-width: 767px) {
    display: none;
  }
}

.header__menu-item {
  text-transform: uppercase;
  margin-right: 15px;
  cursor: pointer;
  transition: $main-transition;

  @mixin hover {
    color: $menu-hover;
  }
  &:active {
    @include hover;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }
}

.menu__btn {
  display: none;
  margin-left: auto;
  font-size: 2rem;
  color: $menu-color;
  cursor: pointer;
  transition: $menu-btn-transition;

  @media (max-width: 767px) {
    display: block;
  }

  &.menu__btn_close {
    transform: rotate(180deg);

    .menu__btn-line {
      &:nth-child(1) {
        transform: rotate(45deg) translate(6px, 6px);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -5px);
      }
    }
  }

  @mixin hover {
    .menu__btn-line {
      background: $menu-hover;
      transition: $menu-btn-transition, background-color 0.2s ease-out;
    }
  }
  &:active {
    @include hover;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }
}

.menu__btn-line {
  width: 28px;
  height: 3px;
  margin: 5px 0;
  background: $menu-color;
  transition: $menu-btn-transition;
}
