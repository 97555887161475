@import "./_variables";

@mixin app-inner-width {
  width: $app-inner-width;
  max-width: 1200px;
}

@mixin section {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}
