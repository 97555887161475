@import "../../utils/css/_variables";
@import "../../utils/css/_mixins";

.exchange {
  @include section;
  background: $exchange-bg;
  color: $exchange-color;
  text-transform: uppercase;
}

.exchange__inner {
  @include app-inner-width;
}

.exchange__desc {
  text-align: center;
  font-size: 1.3rem;
  margin: 1rem;
  line-height: 1.3;

  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

.exchange__contact-link {
  cursor: pointer;
  color: chartreuse;

  @mixin hover {
    color: darken(chartreuse, 10);
  }
  &:active {
    @include hover;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }
}

.exchange__form {
  text-align: center;
}

.exchange__inputs {
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}

.exchange__form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem;

  small {
    position: absolute;
    bottom: -16px;
    font-size: 0.8rem;
    color: $invalid;
  }
}

.exchange__input {
  width: 250px;
  height: 50px;
  padding: 10px 15px;
  outline: 0;
  border: 0;
  border-radius: $border-radius;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
  color: $exchange-bg;
  line-height: normal;
}

.exchange__input_invalid {
  border: 2px solid $invalid;
}

.exchange__btn {
  margin: 1rem;
  padding: 5px 10px;
  background: $calculator-bg;
  color: $exchange-color;
  border: 0;
  outline: 0;
  border-radius: $border-radius;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  text-transform: uppercase;
  cursor: pointer;

  @mixin hover {
    background: darken($calculator-bg, 10);
  }
  &:active {
    @include hover;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }

  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
}

.exchange__error {
  font-size: 0.8rem;
  text-align: center;
}
