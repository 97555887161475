@import "../../utils/css/_variables";
@import "../../utils/css/_mixins";

.attention {
  position: fixed;
  top: $header-height;
  width: 100%;
  background: $attention-bg;
  color: $attention-color;
  border-bottom: 1px solid #fff;
  z-index: 3;
}

.attention__inner {
  @include app-inner-width;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  padding: 0.25rem 0;
  margin: 0 auto;
  font-size: 0.8rem;

  .attention__main {
    margin-right: 1rem;
  }
}

.attention__inner-contacts-link {
  cursor: pointer;
  color: chartreuse;

  @mixin hover {
    color: darken(chartreuse, 10);
  }
  &:active {
    @include hover;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }
}

.attention__close {
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-size: 1.5rem;

  @mixin hover {
    color: darken(rgb(255, 255, 255), 10);
  }
  &:active {
    @include hover;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }

  svg {
    display: flex;
  }
}
