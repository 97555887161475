@import "../../utils/css/_variables";
@import "../../utils/css/_mixins";

.contacts {
  @include section;
  background: $contacts-bg;
  color: $contacts-color;
  text-transform: uppercase;
  font-weight: 300;
}

.contacts__inner {
  @include app-inner-width;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.contacts__info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 2rem 2rem 2rem;

  @media (max-width: 767px) {
    margin: 0 0.5rem 2rem 0.5rem;
  }
}

.contacts__info-unit {
  display: flex;
  align-items: center;
  height: 3rem;
  font-size: 1.5rem;

  @media (max-width: 767px) {
    font-size: 1rem;
  }
}

.contacts__info-unit_clickable {
  color: $contacts-color;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: lighten($contacts-color, 20);
  }

  svg {
    display: flex;
  }
}

.contacts__info-unit-icon {
  margin: 3px 20px 0 0;

  @media (max-width: 767px) {
    margin: 2px 10px 0 0;
  }
}

.contacts__logo {
  margin-bottom: 30px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}

.contacts__map {
  border-radius: $border-radius;
  overflow: hidden;
}

.contacts__map-iframe {
  height: 400px;
  width: 600px;
  max-width: 600px;
  max-height: 80vh;

  @media (max-width: 767px) {
    width: 90vw;
  }
}
