@import "../../utils/css/_variables";

.menu-mobile {
  position: fixed;
  top: $header-height;
  left: -100%;
  width: 100%;
  background: $menu-bg;
  transition: $mobile-menu-transition;
  z-index: $mob-menu-z-i;
}

.menu-mobile_open {
  left: 0;
}

.menu-mobile__item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  max-height: calc((100vh - #{$header-height}) / 4);
  color: $menu-color;
  text-transform: uppercase;
  border-bottom: 1px solid $border;
  cursor: pointer;
  transition: $main-transition;

  @mixin hover {
    color: $menu-hover;
  }
  &:active {
    @include hover;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }
}
