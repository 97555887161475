@import "../../utils/css/_variables";
@import "../../utils/css/_mixins";

@mixin data {
  width: 250px;
  height: 50px;
  background: $calculator-color;
  color: $calculator-bg;
  border-radius: $border-radius;
  transition: $main-transition;
}

.exchange-data__title {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.exchange-data__selected {
  @include data;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  cursor: pointer;

  @mixin hover {
    .exchange-data__select-arrow {
      color: lighten($calculator-bg, 20);
    }
  }
  &:active {
    @include hover;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }
}

.exchange-data__currency-img {
  height: 80%;
  margin: 0 0.5rem;
}

.exchange-data__currency-name {
  flex: 1;
  margin-right: 0.5rem;
  font-size: 2rem;
}

.exchange-data__select-arrow {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.5rem;
  font-size: 2rem;
  transition: $main-transition;
}

.exchange-data__select-arrow_is-opened {
  transform: rotate(-180deg);
}

.exchange-data__select-options {
  @include data;
  position: absolute;
  height: 0;
  padding: 0;
  margin-top: -5px;
  border: 1px solid $calculator-bg;
  overflow: hidden;
  z-index: 1;
}

.exchange-data__select-options-item {
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid $calculator-bg;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }

  @mixin hover {
    background: darken($calculator-color, 10);
  }
  &:active {
    @include hover;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }
}

.exchange-data__input {
  @include data;
  margin-top: 1rem;
  padding: 10px 15px;
  font-size: 2rem;
  line-height: normal;
}
