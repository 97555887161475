// metrics
$app-inner-width: 90%;
$header-height: 60px;
$border-radius: 5px;

// colors
$menu-bg: #222b33;
$menu-color: #fff;
$menu-hover: #009af8;
$calculator-bg: #0084d4;
$calculator-color: #fff;
$exchange-bg: #134069;
$exchange-color: #fff;
$about-us-bg: #f2f3f8;
$about-us-color: #003662;
$contacts-bg: #1f252e;
$contacts-color: #8f9297;
$modal: rgba(#000, 0.9);
$modal-bg: #f2f3f8;
$modal-color: #003662;
$border: #ddd;
$invalid: #ff5588;
$attention-bg: #ff0000;
$attention-color: #ffffff;

// transitions
$main-transition: all 0.15s ease-out;
$menu-btn-transition: all 0.3s ease-out;
$mobile-menu-transition: all 0.3s ease-out;

// z-i
$header-z-i: 10;
$mob-menu-z-i: 10;

:export {
  headerHeight: $header-height;
}
