@import "../../utils/css/_variables";
@import "../../utils/css/_mixins";

.calculator-section {
  @include section;
  background: $calculator-bg;
  color: $calculator-color;
}

.calculator-section__inner {
  @include app-inner-width;
}

.calculator {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.calculator__swaper {
  margin: 1rem 2rem;
  font-size: 3rem;
  cursor: pointer;
  transition: $main-transition;

  @mixin hover {
    color: darken($calculator-color, 20);
  }
  &:active {
    @include hover;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @include hover;
    }
  }

  @media (max-width: 767px) {
    transform: rotate(90deg);
  }
}
